import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 200.000000 205.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,205.000000) scale(0.100000,-0.100000)">


<path d="M1050 1978 c-27 -47 -58 -122 -133 -322 -27 -72 -46 -87 -84 -66 -42
23 -82 91 -101 171 l-18 71 -38 -38 c-79 -79 -131 -236 -124 -374 2 -46 -1
-70 -8 -70 -6 0 -29 18 -51 41 -36 36 -42 39 -47 22 -6 -21 -37 -265 -33 -269
1 -1 47 13 102 31 163 53 282 70 500 69 202 0 297 -12 452 -57 l82 -24 22 51
c13 30 22 72 23 101 0 83 -31 286 -53 345 -20 54 -104 169 -128 177 -6 2 -22
-29 -37 -75 -30 -90 -73 -163 -110 -187 -35 -23 -52 -14 -61 32 -4 21 -30 88
-58 148 -49 105 -51 114 -50 193 0 45 -4 82 -8 82 -5 0 -22 -24 -39 -52z"/>
<path d="M1636 1318 c-2 -7 -7 -29 -10 -48 -4 -19 -18 -52 -32 -73 -14 -21
-24 -40 -22 -42 12 -11 110 -47 115 -42 13 14 5 135 -12 171 -20 41 -33 53
-39 34z"/>
<path d="M1035 1211 c119 -5 203 -15 280 -31 116 -26 118 -41 2 -20 -58 10
-71 10 -85 -3 -9 -8 -35 -20 -58 -27 -36 -11 -45 -10 -68 5 -35 23 -34 2 2
-23 101 -73 263 -79 409 -16 l38 16 -75 29 c-135 53 -246 71 -440 74 l-175 3
170 -7z"/>
<path d="M775 1205 c-5 -2 -45 -11 -88 -20 -47 -10 -75 -20 -71 -27 4 -6 -1
-8 -14 -4 -21 7 -102 -20 -102 -34 0 -14 107 -50 173 -59 95 -13 133 -5 148
31 11 26 10 32 -8 50 -18 17 -23 18 -42 7 -18 -12 -26 -11 -57 6 l-37 20 39 8
c22 4 64 12 94 16 49 8 50 9 15 9 -22 0 -44 -1 -50 -3z m-95 -45 c0 -13 -11
-13 -30 0 -12 8 -11 10 8 10 12 0 22 -4 22 -10z"/>
<path d="M900 1192 c-30 -2 -59 -7 -64 -13 -6 -6 -4 -9 6 -9 13 0 16 -7 12
-32 -3 -18 0 -40 5 -48 9 -13 13 -11 31 8 11 12 24 22 28 22 4 0 20 12 36 26
39 34 21 50 -54 46z"/>
<path d="M986 1131 c-43 -44 -135 -82 -219 -88 -78 -6 -187 17 -270 57 -46 22
-42 24 -83 -52 -56 -109 -68 -269 -28 -383 42 -121 153 -260 279 -350 71 -51
228 -129 303 -151 53 -16 55 -16 144 16 330 115 547 347 565 605 4 58 1 98
-11 144 -19 74 -65 181 -77 181 -5 0 -33 -11 -62 -24 -145 -64 -303 -61 -421
8 -23 13 -50 33 -60 45 -24 27 -27 27 -60 -8z m59 -101 c10 -11 54 -31 97 -45
99 -31 186 -32 281 -4 79 24 78 24 102 -56 25 -81 28 -119 15 -193 -22 -135
-97 -252 -221 -348 -64 -49 -178 -106 -253 -126 -44 -12 -53 -11 -129 16 -295
105 -479 351 -440 589 15 90 38 141 60 132 66 -25 145 -37 219 -32 88 5 192
38 220 68 21 24 27 24 49 -1z"/>
<path d="M995 1006 c-21 -19 -26 -33 -23 -54 2 -16 -1 -34 -8 -40 -19 -18 -45
-119 -38 -147 11 -45 73 -72 102 -43 13 13 13 12 31 -62 6 -25 10 -65 10 -90
-2 -75 13 -125 38 -128 24 -4 73 40 73 65 0 7 7 24 15 37 9 13 15 41 14 62 -2
29 6 52 29 88 36 56 40 76 15 76 -11 0 -4 8 17 19 24 13 35 26 34 40 -1 45 51
14 67 -39 7 -22 15 -40 18 -40 3 0 14 14 23 30 9 17 22 30 28 30 17 0 50 -39
50 -59 0 -10 6 -24 14 -30 11 -9 15 -7 20 11 11 43 5 143 -12 193 -19 55 -21
56 -102 35 -104 -27 -314 4 -370 55 -16 15 -20 14 -45 -9z m82 -52 c9 -8 24
-13 35 -11 10 3 21 -1 26 -7 4 -7 14 -16 22 -19 8 -3 -5 -6 -28 -6 -34 -1 -48
4 -62 21 -15 19 -20 20 -34 9 -21 -17 -33 -7 -16 14 16 19 37 19 57 -1z m153
-144 c0 -18 -19 -11 -30 11 -11 19 -10 20 9 9 12 -6 21 -15 21 -20z"/>
<path d="M537 953 c-8 -15 -18 -50 -22 -76 -8 -54 0 -69 56 -97 37 -19 57 -56
39 -74 -23 -23 4 -117 42 -147 16 -13 21 -27 21 -62 -1 -37 4 -50 28 -73 16
-15 30 -26 32 -23 30 43 56 89 62 107 3 12 11 22 16 22 5 0 9 10 9 22 0 12 5
36 12 54 11 31 10 35 -23 65 -95 91 -109 100 -155 95 -43 -6 -49 -2 -45 31 1
6 -3 14 -8 18 -6 3 -7 13 -4 22 5 13 2 15 -20 9 -27 -6 -33 0 -19 20 10 16 52
28 52 15 0 -6 7 -11 15 -11 8 0 15 5 15 10 0 6 9 19 20 30 29 29 26 34 -27 49
-27 7 -56 15 -65 18 -11 3 -21 -5 -31 -24z"/>
<path d="M626 839 c12 -13 20 -16 22 -9 3 7 -2 16 -9 21 -25 16 -32 9 -13 -12z"/>
<path d="M1410 730 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1232 567 c-17 -20 -25 -64 -13 -72 10 -6 19 9 35 63 8 24 -5 30 -22
9z"/>
<path d="M352 1050 c-58 -41 -53 -48 6 -9 59 40 57 39 45 39 -5 0 -29 -14 -51
-30z"/>
<path d="M1670 1043 c124 -100 188 -179 215 -266 8 -24 14 -33 14 -21 1 31
-54 136 -98 186 -21 24 -67 63 -102 86 -35 24 -48 31 -29 15z"/>
<path d="M238 958 c-51 -53 -108 -142 -108 -168 1 -8 12 9 27 38 14 30 52 83
85 118 33 35 57 64 53 64 -4 0 -29 -23 -57 -52z"/>
<path d="M122 750 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M1903 685 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M113 685 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M1680 664 c0 -4 12 -17 28 -30 30 -27 44 -14 16 17 -16 18 -44 26
-44 13z"/>
<path d="M121 628 c-1 -33 37 -118 76 -169 49 -65 146 -138 248 -188 106 -51
209 -84 236 -76 15 5 19 4 14 -5 -5 -8 -1 -11 11 -9 11 1 26 -1 34 -4 16 -7
161 -27 188 -27 9 0 -23 19 -72 41 -73 33 -97 39 -140 37 -34 -2 -50 -7 -48
-16 3 -17 -4 -16 -84 12 -244 85 -395 209 -445 365 -10 31 -18 48 -18 39z"/>
<path d="M1891 615 c-2 -39 -49 -121 -101 -178 -76 -84 -172 -146 -294 -192
-62 -23 -63 -23 -82 -5 -27 28 -93 14 -219 -44 l-100 -47 60 6 c97 11 264 50
350 84 174 67 305 172 364 293 18 37 31 74 28 85 -4 16 -5 16 -6 -2z"/>
<path d="M1652 583 c-7 -3 -29 -30 -50 -61 -59 -89 -58 -86 -31 -72 20 11 25
10 45 -16 l23 -28 28 24 c56 49 63 61 63 111 0 49 -12 64 -25 32 -6 -17 -7
-17 -16 0 -10 18 -15 19 -37 10z"/>
<path d="M1700 380 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
